import * as React from 'react';
import {Link} from 'gatsby';

import {Container, Row, Col} from 'react-bootstrap';

import {FormattedMessage} from 'gatsby-plugin-intl';

import NewsSection from '@components/common/news-section';

export default function HomeNews({news}) {
  return (
    <div className="home-news pt-lg">
      <Container>
        <Row>
          <Col md={8}>
            <h2>
              <FormattedMessage id="index.news" />
            </h2>
          </Col>
          <Col md={4} className="home-news__see-more d-none d-lg-block">
            <Link to="/aktualnosci">
              <FormattedMessage id="more" />
              &rarr;
            </Link>
          </Col>
        </Row>
        <NewsSection news={news} />
      </Container>
    </div>
  );
}
