import * as React from 'react';
import {Helmet} from 'react-helmet';
import moment from 'moment';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import HomeHero from '@components/home-page/hero';
import HomeNews from '@components/home-page/news';
import HomeAbout from '@components/home-page/about';
import HomeKns from '@components/home-page/kns.js';

const HomeVideoCarousel = React.lazy(() => import('../components/home-page/video-carousel'));

const IndexPage = ({pageContext}) => {
  const filterNews = news => {
    return news.filter(n => moment().valueOf() > n.dateTimestamp).slice(0, 6);
  };

  const isSSR = typeof window === 'undefined';
  const kns = pageContext.homeKns;
  const news = filterNews(pageContext.homeNews);
  const videos = pageContext.homeVideos;
  const numbers = pageContext.homePageNumbers;

  return (
    <Layout>
      <Helmet>
        <title>Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content="Studenckie Koła Naukowe AGH" />
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />

      <HomeHero />
      {news.length > 0 && <HomeNews news={news} />}

      {!isSSR && videos.length > 0 && (
        <React.Suspense fallback={<div />}>
          <HomeVideoCarousel videos={videos} />
        </React.Suspense>
      )}

      <HomeAbout numbers={numbers} />
      {kns.length > 0 && <HomeKns kns={kns} />}

      <Footer />
    </Layout>
  );
};

export default IndexPage;
