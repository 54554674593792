import * as React from 'react';
import {Link} from 'gatsby';

import {Container, Pagination} from 'react-bootstrap';

const OrganisationsGrid = ({kns, paginate, itemsPerPage, tags, searchString, areas}) => {
  const perPage = itemsPerPage || 20;
  const needsPagination = kns.length > perPage;

  const [currentItems, setCurrentItems] = React.useState(null);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    setCurrentPage(0);
    setItemOffset(0);
  }, [tags, searchString, areas]);

  React.useEffect(() => {
    const endOffset = itemOffset + perPage;
    if (paginate) setCurrentItems(kns.slice(itemOffset, endOffset));
    else setCurrentItems(kns);
    setPageCount(Math.ceil(kns.length / perPage));
  }, [kns, currentPage, itemOffset]);

  const handlePageClick = page => {
    console.log('setting page ' + page);
    setCurrentPage(page);
    setItemOffset((page * perPage) % kns.length);
  };

  const renderBoxes = () => {
    if (!Array.isArray(currentItems) || !currentItems.length > 0) return null;

    return currentItems.map(kn => (
      <div className="organisations-section__grid-container__item" key={kn.slug}>
        <Link to={`/kolo/${kn.slug}`}>
          <div style={{height: '100%'}}>
            <div className="organisations-section__grid-container__item--logo-wrapper">
              {kn.logo && <img src={kn.logo} alt={`Logo koła naukowego ${kn.name}`} />}
            </div>
            <div className="organisations-section__grid-container__item--rest-wrapper">
              <h6>{kn.name}</h6>
              <div>{Array.isArray(kn.tags) && kn.tags.map(tag => <span key={tag}>#{tag}</span>)}</div>
            </div>
          </div>
        </Link>
      </div>
    ));
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 0; i < pageCount; i++) {
      pages.push(i);
    }
    return (
      <Pagination size="md">
        {pages.map(item => {
          return (
            <Pagination.Item key={item} active={item === currentPage} onClick={() => handlePageClick(item)}>
              {item + 1}
            </Pagination.Item>
          );
        })}
      </Pagination>
    );
  };

  return (
    <Container>
      <div className="organisations-section">
        {currentItems && <div className="organisations-section__grid-container">{renderBoxes()}</div>}
        {!!paginate && needsPagination && <div className="organisations-section__pagination">{renderPagination()}</div>}
      </div>
    </Container>
  );
};

export default OrganisationsGrid;
