import * as React from 'react';
import {Link} from 'gatsby';

import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from 'gatsby-plugin-intl';

import OrganisationsGrid from '@components/common/organisations-grid.js';

const HomeKns = ({kns}) => {
  return (
    <div className="home-kns">
      <Container>
        <Row>
          <Col md={10}>
            <h2>
              <FormattedMessage id="index.list" />
            </h2>
          </Col>
          <Col md={2} className="home-kns__see-more">
            <Link to="/kola-naukowe">
              <FormattedMessage id="more" />
              &rarr;
            </Link>
          </Col>
        </Row>
      </Container>
      <OrganisationsGrid kns={kns} paginate={false} />
    </div>
  );
};

export default HomeKns;
