import * as React from 'react';
import {Link} from 'gatsby';

import {Row, Col, Image} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl';

import MissingNewsImage from '@components/news/missing-news-image';

export default function NewsSection({news}) {
  const intl = useIntl();

  const [firstNews, ...restNews] = news;
  const firstNewsHasImage = !!firstNews.image;

  return (
    <div className="news-section py-lg">
      <Row>
        <Col md={7}>
          <Link to={`/aktualnosci/${firstNews.slug}`}>
            {firstNewsHasImage && <Image src={firstNews.image} className="mb-4" fluid rounded />}
            {!firstNewsHasImage && <MissingNewsImage className="mb-4" />}
            <span className="mb-2 mt-2">{firstNews.date}</span>
            <h5>
              {intl.locale === 'pl'
                ? firstNews.title
                : firstNews.titleEn === '' || firstNews.titleEn === undefined
                ? firstNews.title
                : firstNews.titleEn}
            </h5>
          </Link>
        </Col>
        <Col md={1}></Col>
        <Col md={4}>
          {restNews.length > 0 && (
            <>
              <h5 className="news-section__newest-news-heading">
                <FormattedMessage id="index.lts" />
              </h5>
              {restNews.map(item => {
                return (
                  <div className="news-section__list-item" key={item.slug}>
                    <span>{item.date}</span>
                    <Link to={`/aktualnosci/${item.slug}`}>
                      {intl.locale === 'pl'
                        ? item.title
                        : item.titleEn === '' || item.titleEn === undefined
                        ? item.title
                        : item.titleEn}
                    </Link>
                  </div>
                );
              })}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
