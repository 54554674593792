import * as React from 'react';

import {Container} from 'react-bootstrap';
import {Link, FormattedMessage} from 'gatsby-plugin-intl';

import bgVideoMp4 from '@images/home-hero-video/bg-video.mp4';
import bgVideoWebm from '@images/home-hero-video/bg-video.webm';
import bgVideoPoster from '@images/home-hero-video/bg-video-poster.jpg';

export default function HomeHero() {
  return (
    <div className="home-hero">
      <video playsInline autoPlay muted loop poster={bgVideoPoster}>
        <source src={bgVideoMp4} type="video/mp4" />
        <source src={bgVideoWebm} type="video/webm" />
      </video>
      <div className="home-hero__content-wrapper">
        <Container>
          <div className="home-hero__container">
            <h1>
              Studenckie Koła
              <br />
              Naukowe AGH
            </h1>
            <div>
              <Link to="/kola-naukowe" className="home-hero__button">
                <FormattedMessage id="index.browse" />
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
